import React from 'react'
import '../styles/ChooseUs.css'

export default function ChooseUs() {
  const aboutUsText = [
    {
      title: 'CALIDAD & TRADICIÓN',
      text: 'Nuestras ollas y cacerolas están diseñadas con el compromiso de proporcionar la mejor experiencia culinaria. Utilizamos materiales de alta calidad y técnicas de fabricación avanzadas, garantizando productos duraderos y eficientes.',
      backgroundColor: '#0AA0A0',
    },
    {
      title: 'COMPROMISO CON LA SOSTENIBILIDAD',
      text: 'Sabemos que la sostenibilidad es importante para ti, y también lo es para nosotros. Por eso, nos esforzamos en ofrecer productos ecológicos y responsables con el medio ambiente. Nuestras cacerolas y ollas están hechas para durar, reduciendo el desperdicio y promoviendo un consumo consciente.',
      backgroundColor: '#F59320',
    },
    {
      title: 'FABRICAMOS PENSANDO EN VOS',
      text: 'Nos enorgullece decir que todo el proceso de fabricación, desde el procesamiento de la materia prima hasta el diseño final, se realiza en nuestra planta de Rosario, cuna de la Bandera. Doña Tina es un producto auténticamente argentino, hecho con amor y dedicación.',
      backgroundColor: '#1A8694',
    },
    {
      title: 'INNOVACIÓN & DISEÑO',
      text: 'Con una inversión constante en tecnología, hemos perfeccionado nuestras técnicas de fabricación para ofrecerte productos modernos y funcionales. Cada olla y cacerola de Doña Tina es una combinación perfecta de tradición e innovación, diseñada para mejorar tu experiencia en la cocina.',
      backgroundColor: '#646464',
    },
  ]
  return (
    <div id='PorqueElegirnos'>
      <div className='choose-us-container'>
        <h1 className=''>¿POR QUÉ ELEGIR DOÑA TINA?</h1>
        <div className='cards-container'>
          {
            aboutUsText.map((item, index) => (
              <div key={index} className='card-container'>
                <div className='card-container-2'>
                  <div className='card-1' style={{ backgroundColor: item.backgroundColor }}>
                    <span>
                      {item.title}
                    </span>
                  </div>
                  <div className='card-2' style={{ backgroundColor: '#fff', borderTop: `28px solid ${item.backgroundColor}`, borderBottom: `28px solid ${item.backgroundColor}` }}>
                    <span>
                      {item.text}
                    </span>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
