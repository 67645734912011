import React from 'react'
import '../styles/HomePage.css'

export default function HomePage() {
  return (
    <div id='homepage' className='homepage-container'>

      <div id="carouselHome" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner w-100 h-100">
          <div className="carousel-item carousel-1 active bg-g">
            <img className='prod' src="/images/home/1.png" alt="Cacerola Doña Tina" />
            <img className='flame' src="/images/home/1.svg" alt="Cacerola Doña Tina" />
            <h1>
              PARA COCINAR <br />
              <b>MEJOR</b>
            </h1>
          </div>

          <div className="carousel-item carousel-2 bg-s">
            <img className='prod' src="/images/home/2.png" alt="Grill Doña Tina" />
            <img className='flame' src="/images/home/2.svg" alt="Grill Doña Tina" />
            <h1>
              <b>LA ESENCIA</b><br />
              DE LA COCINA ARGENTINA
            </h1>
          </div>

          <div className="carousel-item carousel-3 bg-p">
            <img className='prod' src="/images/home/3.png" alt="Sarten Doña Tina" />
            <img className='flame' src="/images/home/3.svg" alt="Sarten Doña Tina" />
            <h1>
              <b>POTENCIA</b><br />
              TUS HABILIDADES EN LA COCINA
            </h1>
          </div>

        </div>
      </div>

      <div className='home-1'>
        <div>
          <img src="/images/icons/logo.png" alt="" />
          <ul>
            <li><a href="#productos">Productos</a></li>
            <li><a href="#nosotros">Nosotros</a></li>
            <li><a href="#red-de-comercios">Red de comercios</a></li>
            <li><a href="#unite">Unite a nuestra comunidad</a></li>
            <li><a href="#contacto">Contacto</a></li>
          </ul>
        </div>

      </div>
    </div>
  )
}
