import React from 'react'
import '../styles/Rights.css'
export default function Rights() {
  return (
    <div className='rights-container'>
      <div className='text-rights'>© 2024 Doña Tina Ollas. Todos los derechos reservados.</div>
      <img className='ms-0 ms-lg-4 mt-3 mt-lg-0' src="/images/icons/logo-spuches.png" alt="Spuches" />
    </div>
  )
}
