import React from 'react'
import '../styles/JoinUp.css'

export default function JoinUp() {
	return (
		<div id='unite' className='join-up-container'>
			<div className='image-container-join'>
				<img src="/images/unete.png" alt="" />
			</div>
			<div className='join-up-text'>
				<h1 className=''>UNITE A NUESTRA COMUNIDAD</h1>
				<p className=''>
          Al elegir nuestros productos, no solo estás adquiriendo herramientas de cocina de alta calidad, sino que también te unes a una comunidad de amantes de la cocina. Comparte tus recetas, inspira a otros y descubre nuevas formas de disfrutar del arte culinario. Síguenos en nuestras redes sociales y sé parte de una red que celebra la buena comida y el placer de cocinar.
				</p>
			</div>
		</div>
	)
}