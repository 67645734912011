import React from 'react';
import '../styles/NavBar.css'

function NavBar() {
  

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid container-navbar">
        <a className="navbar-brand" href="/">
          <img src="/images/icons/logo.png" alt="" />
        </a>
        
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">

            <li className="nav-item">
              <a className="nav-link" href="#productos">PRODUCTOS</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#nosotros">NOSOTROS</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#red-de-comercios">RED DE COMERCIOS</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#unite">UNITE A NUESTRA COMUNIDAD</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contacto">CONTACTO</a>
            </li>
            <li className="nav-item">
              <a target='_blanck' className="nav-link" href="https://www.facebook.com/ollasdonatina?mibextid=ZbWKwL">
                <img src="/images/icons/facebook.png" alt="" />
              </a>
            </li>
            <li className="nav-item">
              <a target='_blanck' className="nav-link" href="https://www.instagram.com/dona__tina">
                <img src="/images/icons/instagram.png" alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavBar