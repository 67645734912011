import React from 'react'
import '../styles/AboutUs.css'

export default function AboutUs() {
	return (
		<div id='nosotros' className='about-us-container'>
			<div className='about-us-text'>
				<h1 className=''>NOSOTROS</h1>
				<p className='mb-n'>
					Desde 1972, Doña Tina ha estado al servicio de la cocina argentina. Todo comenzó con Federico y sus ansias de progreso, haciendo experiencia en un pequeño galpón en Rosario, trabajando en la fundición de diferentes tipos de piezas. Con el paso del tiempo, su dedicación y pasión por la calidad lo llevaron a expandirse y evolucionar.
				</p>
				<p>
				En 1980, nos trasladamos a una nueva estructura más grande, y la familia se unió al negocio. En 2002, su hijo Aníbal lanzó el proyecto de las ollas de aluminio fundido, creando un producto con marca propia que se convirtió en un símbolo de la cocina argentina. Con el tiempo, hemos invertido en tecnología avanzada, evolucionando nuestros métodos de fabricación para ofrecerte lo mejor en utensilios de cocina.
				</p>
			</div>
			<div className='img-container-about'>
				<img src="/images/nosotros.png" alt="" />
			</div>
		</div>
	)
}

