import React, { useState } from 'react'
import '../styles/Contact.css'
import emailjs from '@emailjs/browser';

export default function Contact() {

  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [btnContact, setBtnContact] = useState(false);

 
  function sendEmail(e) {

    e.preventDefault()
  
    if (name.length < 2) {
      setError('El nombre debe tener al menos 2 caracteres.');
      return;
    }
    if (lastname.length < 2) {
      setError('El apellido debe tener al menos 2 caracteres.');
      return;
    }
    if (!email.match(/^\S+@\S+\.\S+$/)) {
      setError('El email ingresado no es válido.');
      return;
    }
    if (tel.length < 5) {
      setError('El teléfono debe tener al menos 5 dígitos.');
      return;
    }
    if (message.length < 6) {
      setError('El mensaje debe tener al menos 6 caracteres.');
      return;
    }

     emailjs.send('service_wyofs8d','template_vmmnieo',{
      name,
      lastname,
      message,
      tel,
      email,
    }, 'ZAziDzuoD3XHLFXWO');
    
    
    setError('');
    setSuccess('Mensaje enviado con éxito.');
    setBtnContact(true)
  }

  return (
    <div id='contacto'>
      <div className='contact-container'>
        <h1>Contáctanos para más información </h1>
        <p>y descubre cómo puedes llevar Doña Tina a más hogares.</p>
        <form className='form-contact' action="">
          <div>
            <input onChange={(e) => setName(e.target.value)} placeholder='Nombre' type="text" />
            <input onChange={(e) => setLastname(e.target.value)} placeholder='Apellido' type="text" />
          </div>
          <div>
            <input onChange={(e) => setEmail(e.target.value)} placeholder='Correo electrónico' type="email" />
            <input onChange={(e) => setTel(e.target.value)} placeholder='Teléfono' type="number" />
          </div>
          <textarea onChange={(e) => setMessage(e.target.value)} placeholder='Mensaje'></textarea>
          {
            error && (
              <span className='text-danger mt-2 text-center f-text'>{error}</span>
            )
          }
          {
            success && (
              <span className='text-success mt-2 text-center f-text'>{success}</span>
            )
          }
          <button disabled={btnContact} onClick={(e) => sendEmail(e)} className='btn-contact'>ENVIAR</button>
        </form>
      </div>
      <img className='img-contact' src="/images/contact.png" alt="Doña Tina Contacto" />
    </div>
  )
}
