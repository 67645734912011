import React, { useEffect, useState } from 'react'
import '../styles/Products.css'
export default function Footer() {

  const [products, setProducts] = useState([]);
  const [categorySelect, setCategorySelect] = useState({});
  /* const [productSelect, setProductSelect] = useState({}); */
  const [productos, setProductos] = useState({});

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('/data.json');
      const jsonData = await response.json();
      setProducts(jsonData.productos);
    }
    fetchData();
  }, []);

  function setCategory(category) {
    setCategorySelect(category)
    /* setProductSelect(category.productos[0]) */
    setProductos(category.productos)


  }

  return (
    <div id='productos' className=''>
      <h1>conocé nuestros productos</h1>
      <div className='products-container'>
        {
          products && (
            products.map((category, index) => (
              <div
                className='products-select'
                key={index}
                onClick={() => setCategory(category)}
                data-bs-target="#carouselProducts" data-bs-slide-to={'0'}
                style={
                  {
                    backgroundColor: categorySelect.categoria === category.categoria ? `#646464` : `#fff`,
                    color: categorySelect.categoria === category.categoria ? `#fff` : `#646464`
                  }
                }
              >
                <div
                  className='product-bg'
                  style={{
                    backgroundImage: categorySelect.categoria === category.categoria ? `url(${category.logoBlanco})` : `url(${category.logoGris})`
                  }}
                ></div>
                <h3>{category.categoria}</h3>
              </div>
            ))
          )
        }
      </div>

      {
        Object.keys(categorySelect).length > 0 && (

          <div id="carouselProducts" className="carousel slide carousel-dark">
            <div className="carousel-indicators">

              {
                productos.map((product, index) => (
                  <button key={index} type="button" data-bs-target="#carouselProducts" data-bs-slide-to={index} className={index === 0 ? "button-c active" : 'button-c'} aria-current={index === 0 ? "true" : ""} >{product.nombre}</button>
                ))
              }
            </div>
            <div className="carousel-inner">

              {
                productos.map((product, index) => (
                  <div key={index} className={index === 0 ? "carousel-item active" : 'carousel-item'}>
                    <img src={`/images/productos/${product.imagen}`} className="product-img" alt="Producto Doña Tina" />
                    <div className="products-info-container">
                      <div className={product.profundidad ? 'info-p-container with-depth' : 'info-p-container without-depth'}>
                        <div className='info-data'>
                          <img alt='Doña Tina' src="/images/icons/caracteristicas/ancho.png" />
                          <span>{product.ancho}</span>
                        </div>
                        <div className='info-data'>
                          <img alt='Doña Tina' src="/images/icons/caracteristicas/alto.png" />
                          <span>{product.alto}</span>
                        </div>
                        {
                          product.profundidad && (
                            <div className='info-data'>
                              <img alt='Doña Tina' src="/images/icons/caracteristicas/profundidad.png" />
                              <span>{product.profundidad}</span>
                            </div>
                          )
                        }
                        <div className='info-data'>
                          <img alt='Doña Tina' src="/images/icons/caracteristicas/comen.png" />
                          <span>{product.comensales}</span>
                        </div>
                        <div className='info-data'>
                          <h3>Litros</h3>
                          <span>{product.litros}</span>
                        </div>
                      </div>
                      <div className='colors-container'>
                        <h4>con y sin antiadherente</h4>
                        <div className='color-container'>
                          {
                            product.colores.map((color, index) => (
                              <div key={index} style={{ backgroundColor: color }} className='color-product'></div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselProducts" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselProducts" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>

        )
      }


    </div>
  )
}
