import HomePage from "./components/HomePage";
import AboutUs from "./components/AboutUs";
import ChooseUs from "./components/ChooseUs";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Rights from "./components/Rights";
import NavBar from "./components/NavBar";
import JoinUp from "./components/JoinUp";
import Networks from "./components/Networks";
import Products from "./components/Products";

function App() {
  return (
    <div className="App">
      <HomePage />
      <NavBar />
      <Products />
      <AboutUs />
      <ChooseUs />
      <JoinUp />
      <Networks />
      <Contact />
      <Footer />
      <Rights />
    </div>
  );
}

export default App;
