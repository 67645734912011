import React from 'react'
import '../styles/Networks.css'

export default function Networks() {
	return (
		<div id='red-de-comercios'>
			<div className='net-container'>
				<h1 className=''>red de comercios</h1>
				<p className=''>
					Si deseas formar parte de nuestra red de distribuidores y comercios, te ofrecemos:
				</p>
					<ul>
						<li>- Productos de alta calidad</li>
						<li>- Asesoramiento técnico</li>
						<li>- Servicio de ventas</li>
						<li>- Servicio de entrega</li>
						<li>- Apoyo comercial</li>
					</ul>
					<a href="/">¡quiero formar parte!</a>
			</div>

			<div className='img-network'>
				<img src="/images/red.png" alt="redes construcción" />
			</div>

		</div>
	)
}