import React from 'react'
import '../styles/Footer.css'
export default function Footer() {

  return (
    <div id='footer' className='footer-container'>
      <div className=''>
        <img className='logo-footer' src="/images/icons/logo.png" alt="Aluminio Fundido" />
      </div>
      <div className='social-footer'>
        <a target='_blanck' href="/">Dirección: Av. Arijón 1734, Rosario, Santa Fe</a>
        <a target='_blanck' href="tel:3414651290">Teléfono: 341-4651290</a>
        <a target='_blanck' href="https://wa.me/3412713560">WhatsApp: +54 9 3412713560</a>
        <a target='_blanck' href="mailto:contacto@ollasdoñatina.com.ar">Correo electrónico: contacto@ollasdoñatina.com.ar</a>
      </div>
    </div>
  )
}
